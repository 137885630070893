@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Core";
  src: url("media/fonts/S-Core\ -\ Core\ Circus.otf");
}
@layer base {
  h1 {
    font-family: "Core";
  }
  body {
    font-family: "Core";
  }
}
